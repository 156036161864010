import "./App.css";
import React, {useEffect} from "react";



function App() {
  useEffect(() => {
    const goToHomePage = () =>
      window.open("https://www.brazilgame.io", "_self");
    goToHomePage(); // This will redirect immediately upon component mount
  }, []); // Empty dependency array ensures this effect runs only once, on mount

  return (
    <div className="App">
      <header className="App-header">
        <div className="loader"></div> {/* class should be className in JSX */}
      </header>
    </div>
  );
}

export default App;
